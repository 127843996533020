import BaseRESTService from "./BaseRESTService";
const base = new BaseRESTService();

export const subscribeNotification = (url, accessToken) => {
  return base.run('/notifications/resubscribe', {
    method: 'POST',
    headers: {
      Authorization: accessToken
    },
    params: {
      url
    }
  });
};

export const unsubscribeNotification = (url, accessToken) => {
  return base.run('/notifications/unsubscribe', {
    method: 'POST',
    headers: {
      Authorization: accessToken
    },
    params: {
      url
    }
  });
};

export const snoozeNotification = (url, accessToken) => {
  return base.run('/notifications/snooze-notifications', {
    method: 'POST',
    headers: {
      Authorization: accessToken
    },
    params: {
      url
    }
  });
};

export const unsnoozeNotification = (url, accessToken) => {
  return base.run('/notifications/unsnooze-notifications', {
    method: 'POST',
    headers: {
      Authorization: accessToken
    },
    params: {
      url
    }
  });
};

export const activateRepo = (url, accessToken) => {
  return base.run('/notifications/repo-on', {
    method: 'POST',
    headers: {
      Authorization: accessToken
    },
    params: {
      url
    }
  });
};

export const suspendRepo = (url, accessToken) => {
  return base.run('/notifications/repo-off', {
    method: 'POST',
    headers: {
      Authorization: accessToken
    },
    params: {
      url
    }
  });
};
