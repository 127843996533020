const UNSUBSCRIBE_EMAIL = {
  TEXT: 'Your unsubscribe request has been successfully processed. You will no longer receive further reminders from PRT.',
  QUESTION: 'Did you unsubscribe by accident?',
  LINK: 'Click here to re-subscribe',
  THANK: 'You have been successfully re-subscribed to the notifications.'
};

const SNOOZE_EMAIL = {
  TEXT: 'Your request has been successfully processed. You won\'t receive reminders for ProjectName for the next 5 days.',
  QUESTION: 'Did you snooze these notifications by accident?',
  LINK: 'Click here to re-enable notifications',
  THANK: 'Notifications are turned ON again.'
};

const REPO_OFF = {
  TEXT: 'Repository notifications are turned OFF!',
  QUESTION: 'Did you turn OFF notifications for this Repository by accident?',
  LINK: 'Click here to turn notifications ON',
};

const REPO_ON = {
  TEXT: 'Repository notifications are turned ON!',
  QUESTION: 'Did you turn ON this notifications for this Repository by accident?',
  LINK: 'Click here to turn notifications OFF',
};

const TITLE = 'Thank you!';

export {
  UNSUBSCRIBE_EMAIL,
  SNOOZE_EMAIL,
  REPO_OFF,
  REPO_ON,
  TITLE
}