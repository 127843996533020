import React from 'react';
import {Row} from 'reactstrap';
import '../../styles/Header.css';
import materialIcon from '../../assets/images/MaterialIcon.svg';

export default React.memo(({status}) => {
  return (
    <Row className='status-container'>
      <img src={materialIcon} className="status-icon" alt="Status" />
      <span className='status-text'>System Status:</span>
      <span className='status-text status'>{status}</span>
    </Row>
  );
});