import React from 'react';
import {Row} from 'reactstrap';
import '../../styles/Header.css';

export default React.memo(({updated}) => {
  return (
    <Row className='statistics-container'>
      <span className='statistics-text bold'>Statistics</span>
      <div>
        <span className='statistics-text bold'>Updated:</span>
        <span className='statistics-text'>{updated}</span>
      </div>
    </Row>
  );
});