import React from 'react';
import { ListGroup, ListGroupItem } from 'reactstrap';
import snoozed from "../../assets/images/snooze.svg";

export default React.memo(({users}) => {
  return (
    <ListGroup>
      {users.map((user, index) => {
        return (
          <ListGroupItem key={user.id} color={!(index % 2) ? "success" : ''}>
            {user.snoozed && <img className='snoozed' src={snoozed} alt="Italian Trulli" />}
            {`${index+1}. ${user.name} - ${user.repository}`}
          </ListGroupItem>
        )
      })}
    </ListGroup>
  );
});
