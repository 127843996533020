import React, {useState} from 'react';
import {Card, CardBody, Collapse, Row} from 'reactstrap';

import List from "./List";
import Badge from "./Badge";
import arrowBottom from "../../assets/images/arrow-bottom.svg";
import arrowRight from "../../assets/images/arrow-right.svg";
import '../../styles/Header.css';

export default React.memo(({users, count}) => {
  const [isOpen, setIsOpen] = useState(false);
  const toggle = () => setIsOpen(!isOpen);
  const icon = isOpen ? arrowBottom : arrowRight;

  return (
    <Row className='push-container'>
      <div className='push-required'>
        <div onClick={toggle} className='push-button rec-color'>
          <div>
            <img src={icon} className="arrow-icon" alt="Status" />
            <span>Push recommended</span>
          </div>
          <Badge text={count}/>
        </div>
        <Collapse isOpen={isOpen}>
          <Card>
            <CardBody>
              <List users={users} />
            </CardBody>
          </Card>
        </Collapse>
      </div>
    </Row>
  );
});