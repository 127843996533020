import React, { useEffect, useState } from 'react';
import MainLayout from "../MainLayout";
import Home from "../Home";
import Subscription from "../Subscription";
import Repo from "../Repo";
const REPO_ACTIONS = ['repo-on', 'repo-off'];
const NOTIFICATION_ACTIONS = ['unsubscribe', 'snooze-notifications'];

export default React.memo(() => {
  const [isHome, setIsHome] = useState(false);
  const [projectName, setProjectName] = useState('');
  const [action, setAction] = useState('');
  const [repoURL, setRepoURL] = useState('');

  useEffect(() => {
    const path = window.location.pathname;
    if (path !== '/') {
      let arr = path.split('/');
      arr.shift();
      setAction(arr[0]);
      let repoURL = `${arr[1]}//${arr[3]}/${arr[4]}`;
      let projectName = arr[4];
      if (arr[3].includes('gitea')) {
        repoURL = `${repoURL}/${arr[5]}`;
        projectName = arr[5];
      }
      setRepoURL(repoURL);
      setProjectName(projectName);
    } else {
      setIsHome(true);
    }
  }, []);

  return (
    <MainLayout>
      {isHome && <Home />}
      {REPO_ACTIONS.includes(action) && <Repo action={action} repoURL={repoURL} />}
      {NOTIFICATION_ACTIONS.includes(action) && <Subscription action={action} repoURL={repoURL} projectName={projectName} />}
    </MainLayout>
  );
});
