import React from 'react';
import { Row } from 'reactstrap';
import '../styles/Header.css';
import logo from '../assets/images/logo.png';

export default React.memo(() => {
  return (
    <Row className='header'>
      <img src={logo} className="image" alt="logo" />
      <span className='header-text'>Scopic Push Reminder</span>
    </Row>
  );
});
