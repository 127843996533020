import React from 'react';
import {Container} from 'reactstrap';
import { Header, Footer } from '../../Components';

export default React.memo(({children}) => {
  return (
    <div className="app">
      <Header />
      <Container>
        {children}
      </Container>
      <Footer />
    </div>
  );
});
