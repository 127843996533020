import React, { useEffect, useState, useCallback } from 'react';
import { Spinner } from '../../Components';
import Status from './Status';
import Statistics from './Statistics';
import PushRequired from './PushRequired';
import PushRecommended from './PushRecommended';
import { getPanelData } from '../../services/home';
import '../../styles/Home.css';
import { useKeycloak } from '@react-keycloak/web';

export default React.memo(() => {
  const [redFlagUsers, setRedFlagUsers] = useState([]);
  const [yellowFlagUsers, setYellowFlagUsers] = useState([]);
  const [status, setStatus] = useState('');
  const [updated, setUpdated] = useState('');
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState('');
  const { initialized, keycloak } = useKeycloak();

  const fetchData = useCallback(async (num) => {
    try {
      const getPanelResponse = await getPanelData(keycloak.token);
      if (getPanelResponse.status !== 200) {
        console.log(getPanelResponse);
        setLoading(false);
        setError('Server error');
        return
      }
      const { status, lastUpdated, redFlagUsers, yellowFlagUsers } = getPanelResponse?.data;
      setRedFlagUsers(redFlagUsers || []);
      setYellowFlagUsers(yellowFlagUsers || []);
      setStatus(status);
      setUpdated(lastUpdated);
      setLoading(false);
    } catch (e) {
      setLoading(false);
      setError('Server error')
    }
  }, [keycloak]);

  useEffect(() => {
    if (initialized) {
      fetchData();
    }
  }, [fetchData, initialized]);

  if (!initialized) {
    return <div style={{ marginTop: '3rem' }}>
      <Spinner />
    </div>
  }

  return (
    <>
      <Status status={status} />
      <Statistics updated={updated} />
      <div className='error'>{error}</div>
      <PushRequired users={redFlagUsers} count={redFlagUsers.length} />
      <PushRecommended users={yellowFlagUsers} count={yellowFlagUsers.length} />
      {loading && <Spinner />}
    </>
  );
});
