import React from 'react';
import '../styles/Home.css';

export default React.memo(({text}) => {
  return (
    <div className="footer">
      <span>Push Reminder Tool 1.0</span>
      <span>2023</span>
    </div>
  );
});
