import BaseRESTService from "./BaseRESTService";
const base = new BaseRESTService();

export const getPanelData = (accessToken) => {
  return base.run('/adminpanel/paneldata', {
    method: 'GET',
    headers: {
      Authorization: `Bearer ${accessToken}`
    },
  });
};
