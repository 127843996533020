import React, { useCallback, useEffect, useState } from 'react';
import { Row, Button } from 'reactstrap';
import { UNSUBSCRIBE_EMAIL, SNOOZE_EMAIL, TITLE } from '../../assets/constants/texts'
import '../../styles/ThankYou.css';
import { unsubscribeNotification, subscribeNotification, snoozeNotification, unsnoozeNotification } from "../../services/notification";
import { Spinner } from "../../Components";
import { useKeycloak } from '@react-keycloak/web';

export default React.memo(({ action, repoURL, projectName }) => {
  const [loading, setLoading] = useState(false);
  const [thanks, setThanks] = useState(false);
  const [error, setError] = useState('');
  const { keycloak, initialized } = useKeycloak();

  const handleOnClick = useCallback(async () => {
    setError('');
    setLoading(true);
    try {
      if (action === 'unsubscribe') {
        await subscribeNotification(repoURL, keycloak.token);
        setThanks(true);
        setLoading(false);
      } else if (action === 'snooze-notifications') {
        await unsnoozeNotification(repoURL, keycloak.token);
        setThanks(true);
        setLoading(false);
      }
    } catch (e) {
      setError('Server Error');
      setLoading(false);
    }
  }, [action, repoURL, keycloak]);

  const fetchData = useCallback(async () => {
    setError('');
    try {
      if (action === 'unsubscribe') {
        await unsubscribeNotification(repoURL, keycloak.token);
      } else if (action === 'snooze-notifications') {
        await snoozeNotification(repoURL, keycloak.token);
      }
    } catch (e) {
      setError('Server Error');
    }
  }, [action, repoURL, keycloak]);

  useEffect(() => {
    if (initialized) {
      fetchData()
    }
  }, [action, repoURL, initialized, fetchData]);

  const SNOOZE_EMAIL_TEXT = SNOOZE_EMAIL.TEXT.replace('ProjectName', projectName);
  const TEXT = action === 'unsubscribe' ? UNSUBSCRIBE_EMAIL.TEXT : SNOOZE_EMAIL_TEXT;
  const QUESTION = action === 'unsubscribe' ? UNSUBSCRIBE_EMAIL.QUESTION : SNOOZE_EMAIL.QUESTION;
  const LINK = action === 'unsubscribe' ? UNSUBSCRIBE_EMAIL.LINK : SNOOZE_EMAIL.LINK;
  const THANK = action === 'unsubscribe' ? UNSUBSCRIBE_EMAIL.THANK : SNOOZE_EMAIL.THANK;

  return (
    <>
      <Row className='thank-you-container'>
        <h1>{TITLE}</h1>
        {thanks ? (
          <div>
            <div>{THANK}</div>
          </div>
        ) : (
          <>
            <div>
              <div>{TEXT}</div>
            </div>
            <div>
              <span>{QUESTION}</span>
              <Button color='link' onClick={handleOnClick}>{LINK}</Button>
            </div>
            <div className='error'>
              {error}
            </div>
          </>
        )}
      </Row>
      {loading && <Spinner />}
    </>
  );
});
