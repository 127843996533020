import Keycloak from 'keycloak-js';
import config from './config';

const keycloak = Keycloak({
    clientId: config.REACT_APP_KEYCLOAK_CLIENT_ID,
    url: config.REACT_APP_KEYCLOAK_SERVER_URL,
    realm: config.REACT_APP_KEYCLOAK_REALM,
});

export const initOptions = {
    onLoad: 'login-required',
    checkLoginIframe: false
};

export default keycloak; 
