import axios from 'axios';
import config from '../config';

export default class BaseRESTService {

  run(route, options) {

    let headers = {
      'Content-Type': 'application/json',
    };

    if (options.headers) {
      headers = { ...headers, ...options.headers }
    }

    let axiosConfig = {
      method: options.method,
      url: route,
      data: options.data,
      headers
    };

    if (Boolean(config.REACT_APP_API_URL)) {
      axiosConfig = {
        method: options.method,
        baseURL: config.REACT_APP_API_URL,
        url: route,
        data: options.data,
        headers
      };
    }


    if (options.params) {
      axiosConfig.params = options.params;
    }

    return axios(axiosConfig);
  }
}
