import React, { useCallback, useEffect, useState } from 'react';
import { Row, Button } from 'reactstrap';
import { REPO_OFF, REPO_ON, TITLE } from '../../assets/constants/texts'
import '../../styles/ThankYou.css';
import { activateRepo, suspendRepo } from "../../services/notification";
import { Spinner } from "../../Components";
import { useKeycloak } from '@react-keycloak/web';

export default React.memo(({ action, repoURL }) => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');
  const [isRepoOn, setIsRepoOn] = useState(true);
  const { keycloak, initialized } = useKeycloak();

  const handleOnClick = useCallback(async () => {
    setLoading(true);
    setError('');
    try {
      if (isRepoOn) {
        await suspendRepo(repoURL, keycloak.token);
        setIsRepoOn(false);
      } else {
        await activateRepo(repoURL, keycloak.token);
        setIsRepoOn(true);
      }
      setLoading(false);
    } catch (e) {
      setLoading(false);
      setError('Server Error')
    }
  }, [repoURL, keycloak, isRepoOn]);

  const fetchData = useCallback(async () => {
    setLoading(true);
    setError('');
    try {
      if (action === 'repo-on') {
        await activateRepo(repoURL, keycloak.token);
        setIsRepoOn(true);
      } else if (action === 'repo-off') {
        await suspendRepo(repoURL, keycloak.token);
        setIsRepoOn(false);
      }
      setLoading(false);
    } catch (e) {
      setLoading(false);
      setError('Server Error')
    }
  }, [action, repoURL, keycloak]);

  useEffect(() => {
    if (initialized) {
      fetchData();
    }
  }, [action, repoURL, initialized, fetchData]);

  const TEXT = isRepoOn ? REPO_ON.TEXT : REPO_OFF.TEXT;
  const QUESTION = isRepoOn ? REPO_ON.QUESTION : REPO_OFF.QUESTION;
  const LINK = isRepoOn ? REPO_ON.LINK : REPO_OFF.LINK;

  return (
    <>
      <Row className='thank-you-container'>
        <h1>{TITLE}</h1>
        <div>
          <div>{TEXT}</div>
        </div>
        <div>
          <span>{QUESTION}</span>
        </div>
        <div>
          <Button color='link' onClick={handleOnClick}>{LINK}</Button>
        </div>
        <div className='error'>
          {error}
        </div>
      </Row>
      {loading && <Spinner />}
    </>
  );
});
