const { cleanEnv, str, num } = require('envalid');
const dotenv = require('dotenv');
const path = require('path');

const currentEnv = process?.env?.NODE_ENV ?? '';
if (['development', 'local', 'test'].includes(currentEnv)) {
    if (currentEnv === 'test') {
        dotenv.config({
            path: path.join(__dirname, '.env.test')
        });
    } else {
        dotenv.config();
    }
}

const config = cleanEnv(process.env, {
    REACT_APP_PORT: num({ default: 8080 }),
    REACT_APP_API_URL: str({ default: '/api' }),
    REACT_APP_KEYCLOAK_CLIENT_ID: str({ default: 'push-reminder-tool' }),
    REACT_APP_KEYCLOAK_SERVER_URL: str({ default: 'http://localhost:8080' }),
    REACT_APP_KEYCLOAK_REALM: str({ default: 'master' }),
});

module.exports = config;